<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{$ml.get('MARK_AS_PAID')}}
        </h3>

        <div class="meta-data pt-4">
            <div class="row">
                <div class="col-6">
                    <div class="row pb-2">
                        <div class="col-6">
                            <strong>{{$ml.get('Invoice_number')}}:</strong>
                        </div>
                        <div class="col-6">{{form.number}}</div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-6">
                            <strong>{{$ml.get('Invoice_date')}}:</strong>
                        </div>
                        <div class="col-6">{{form.issuedOn | dateFormat }}</div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="row pb-2">
                        <div class="col-8 col-md-6">
                            <strong>{{$ml.get('Invoice_netAmount')}}:</strong>
                        </div>
                        <div class="col-4  col-md-6">{{ {amount: form.netAmount, currency: form.currency } | currency }}</div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-8  col-sm-6">
                            <strong>{{$ml.get('Invoice_grossAmount')}}:</strong>
                        </div>
                        <div class="col-4 col-sm-6">{{ {amount: form.amount, currency: form.currency } | currency }}</div>
                    </div>
                </div>

            </div>







        </div>



        <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
                <input-field :id="`paidOn-date`"
                             :name="'paidOn-date'"
                             type="date"
                             :value="form.paidOn"
                             v-model="form.paidOn">{{$ml.get('PAID_ON')}}
                </input-field>
            </div>

        </div>

        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id == null)?'plus':'save'"
                              type="submit"
                              :disabled="isFormDisabled"
                              block>{{(id == null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "InvoiceForm",
        computed: {
            id(){
              let invObj = this.$store.getters.selectedModalData('selectedInvoices');
              if(invObj) {
                  return invObj.id;
              }
              else {
                  return null;
              }
            },

            selectedInvoice() {
                return this.$store.getters.selectedModalData('selectedInvoices');
            },

            isFormDisabled(){
                return !this.form.paidOn;
            }
        },
        data() {
            let initialAttr = this.$store.getters.selectedModalData('selectedInvoices');
            return {
                fileTrigger: {},
                previewFiles: {
                    attachment: initialAttr && initialAttr.attributes.attachment ? initialAttr.attributes.attachment._url : ''
                },
                imgFiles: {
                    attachment: null
                },
                form: {
                    number: initialAttr ? initialAttr.attributes.number : '',
                    currency:  initialAttr ? initialAttr.attributes.currency : 'EUR',
                    issuedOn:  initialAttr ? initialAttr.attributes.issuedOn.toISOString().slice(0, 10) : '',
                },
                loading: false,
                errors: []
            }
        },
        mounted() {

            if (this.selectedInvoice !== null) {
                this.form = {...this.form, ...this.selectedInvoice.attributes}
                this.form.select.value = this.form.currency;
            }

            //Set default address if new item
            if(!this.form.select.value) {
                this.form.select.value = this.form.select.options[0].value;
                this.form.currency = this.form.select.options[0].value;
            }
        },
        methods: {

            submitForm() {
                let self = this;
                if (!this.loading) {

                    this.loading = true;
                    this.form.company = this.$store.getters.getCardCompany;

                    let invoiceId = this.selectedInvoice ? this.selectedInvoice.id : null;
                    this.form.attachment = null;
                    let year = this.selectedInvoice ? this.selectedInvoice.attributes.issuedOn.getFullYear() : new Date().getFullYear();

                    this.$store.dispatch('saveInvoice', {id: invoiceId, ...this.form}).then(() => {
                        this.$store.dispatch('getClientInvoices', {id: this.form.company.id, year: year}).then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_saved'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    });
                }
            }
        },
        watch: {
            fileTrigger: {
                handler(newVal) {
                    if(newVal){
                        this.imgFiles[newVal.field] = newVal.file;
                        this.previewFiles[newVal.field] = newVal.name;
                        this.defaultsClicked = 0;
                    }
                },
                deep: true,
            },
        }
    }
</script>

<style scoped>
.meta-data {
    border: 1px solid #e5e5e5;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 12px;
}
</style>
