<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">

            <div class="qf-charts pt-3">
                <div class="qf-stats-container">
                    <vue-apex-charts
                        id="bars"
                        v-if="series.length > 0"
                        height="200"
                        :options="chartOptions"
                        :series="series"
                    />
                </div>
                <div class="qf-stats-container">
                    <vue-apex-charts
                        id="maps"
                        v-if="hasMapEntries"
                        height="200"
                        :options="mapOptions"
                        :series="mapSeries"
                    />
                </div>
            </div>


            <div class="qf-table-mode-controls text-center py-2">
                <b-form-group
                    :label="$ml.get(`ALLTIME_OR_MONTHLY`)"
                >
                    <b-form-radio-group
                        id="list-selector"
                        v-model="toggleMode"
                        :options="listOptions"
                        button-variant="secondary"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                    ></b-form-radio-group>
                </b-form-group>
            </div>

            <div class="table-top">
                <div class="table-top-buttons">
                </div>
                <div class="table-count">
                    <span class="card-count">{{$ml.get('TOTAL_CARD_COUNT')}}: {{resultsFrom}} - {{resultsTo}} / {{totalResults}}</span>
                </div>
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name')"
                    />
                </div>
            </div>

            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="tableRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >
                        <template v-slot:head(checkbox)="data">
                            <b-form-checkbox
                                class="d-inline-block pl-0 accent-secondary b-table-checkbox"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedRows[data.item.id]"
                                :value="data.item.id"
                                variant="secondary"
                                class="accent-secondary b-table-checkbox"

                            />
                        </template>

                        <template #cell(attributes.logo)="data">
                            <div class="circular-logo">
                                <img v-if="data.item.attributes.logo"
                                     :src="data.item.attributes.logo._url"/>
                            </div>
                        </template>

                        <template #cell(attributes.billingStart)="data">
                            <div class="ellipsis">
                                {{ data.item.attributes.billingStart | dateFormat}}
                            </div>
                        </template>

                        <template #cell(attributes.totalInvoiced)="data">
                            <div class="ellipsis">
                                {{{currency: 'EUR', amount:data.item.attributes.totalInvoiced }  | currency}}
                            </div>
                        </template>

                        <template #cell(attributes.totalPaid)="data">
                            <div class="ellipsis">
                                {{{currency: 'EUR', amount:data.item.attributes.totalPaid }  | currency}}
                            </div>
                        </template>

                        <template #cell(attributes.totalUsed)="data">
                            <div class="ellipsis">
                                {{{currency: 'EUR', amount:data.item.attributes.totalUsed }  | currency}}
                            </div>
                        </template>

                        <template #cell(balance)="data">
                            <div class="ellipsis"
                                 :class="data.item.attributes.totalPaid - data.item.attributes.totalUsed >= 0 ? 'text-success' : 'text-danger'">
                                {{{currency: 'EUR', amount: (data.item.attributes.totalPaid - data.item.attributes.totalUsed) }  | currency}}
                            </div>
                        </template>



                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field @click="copyLink(data.item)"
                                              circle
                                              outline
                                              v-tooltip="`Add new invoice for ${data.item.attributes.name}`"
                                              icon="plus"
                                              class="table-action-btn"
                                              color="secondary"
                                              :disabled="hasExpired(data.item)"
                                ></button-field>
                                <button-field @click="copyLink(data.item)"
                                              circle
                                              outline
                                              v-tooltip="`Go to billing details`"
                                              icon="link"
                                              class="table-action-btn"
                                              color="dark"
                                              :disabled="hasExpired(data.item)"
                                ></button-field>

                            </div>
                        </template>

                    </b-table>
                </div>
            </div>

            <div class="table-bottom">
                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>
                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>
            </div>
        </div>

        <b-sidebar
                id="promo-form-modal"
                class="bg-white"
                title="Edit Promo"
                right
                width="500px"
                shadow
                @hidden="fetchPromos">

            <div class="form-group">
                <validation-observer ref="promo-form">

                    <b-form-group
                        label="Promo code"
                        label-for="promo-code"
                        class="required"
                    >
                        <validation-provider rules="required" name="Promo code" v-slot="{ errors }">
                            <b-form-input
                                id="promo-code"
                                v-model="currentPromo.code"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : ''"
                            ></b-form-input>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        label="Description (visible in orange stripe)"
                        label-for="promo-code"
                        class="required"
                    >
                        <validation-provider rules="required" name="Promo code" v-slot="{ errors }">
                            <b-form-textarea
                                id="promo-code"
                                rows="3"
                                v-model="currentPromo.description"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : ''"></b-form-textarea>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                        </validation-provider>
                    </b-form-group>

                    <b-row>
                        <b-col cols="6">
                            <b-form-group
                                label="Valid until"
                                label-for="promo-code"
                                class="required"
                            >
                                <validation-provider rules="required" name="Promo code" v-slot="{ errors }">
                                    <b-form-datepicker
                                        id="promo-code"
                                        v-model="currentPromo.validUntil"
                                        :state="errors.length > 0 ? false : null"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"></b-form-datepicker>
                                    <div class="invalid-feedback">{{ errors[0] }}</div>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="6">
                            <b-form-group
                                label="Trial duration (in days)"
                                label-for="trial-duration"
                                class="required"
                            >
                                <b-form-input type="number"
                                              id="trial-duration"
                                              :disabled="!currentPromo.allowTrial"
                                              v-model="currentPromo.trialDuration">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>


                </validation-observer>
            </div>
        </b-sidebar>

    </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueApexCharts from "vue-apexcharts";

export default {
    name: "PromoList",
    components: {
        VueApexCharts,
        ValidationProvider,
        ValidationObserver
    },
    data(){
        return {
            something: false,
            toggleMode: 'allTime',
            listOptions: [
                { text: 'All time', value: 'allTime' },
                { text: 'Current month', value: 'monthly' }
            ],

            searchString: '',
            currentPromo: {
                attributes: {

                }
            },
            emptyHtml: '<div class="empty-table">No results found</div>',
            selectAllStatus: false,
            selectedRows: {},
            tableColumns: [
                {key: 'checkbox', label: 'Select', class: 'checkbox-column'},
                { key: 'attributes.logo', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                { key: 'attributes.name', label: 'Company name',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.billingStart', label: 'Billing start',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalInvoiced', label: 'Billed', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalPaid', label: 'Paid', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalUsed', label: 'Usage', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'balance', label: 'Balance', thClass: 'font-bebas-neue font-small-4', sortable: true},
                {
                    key: 'actions',
                    label: 'Actions',
                    thClass: 'font-bebas-neue text-center font-small-4',
                    tdClass: 'text-right',
                    sortable: false
                }
            ],
            monthlyColumns: [
                { key: 'checkbox', label: 'Select', class: 'checkbox-column'},
                { key: 'attributes.logo', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                { key: 'attributes.name', label: 'Company name',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.billingStart', label: 'Billing start',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalInvoiced', label: 'Billed', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalPaid', label: 'Paid', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalUsed', label: 'Usage', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'balance', label: 'Balance', thClass: 'font-bebas-neue font-small-4', sortable: true},
                {
                    key: 'actions',
                    label: 'Actions',
                    thClass: 'font-bebas-neue text-center font-small-4',
                    tdClass: 'text-right',
                    sortable: false
                }
            ],

            /* PAGINATION STUFF */
            curPageValue: 1,
            totalResults: 0,

            perPageOptions: [
                {
                    value: 5,
                    text: 5
                },
                {
                    value: 10,
                    text: 10
                },
                {
                    value: 25,
                    text: 25
                },
                {
                    value: 50,
                    text: 50
                },
                {
                    value: 100,
                    text: 100
                },
            ],
            curPageOptions: [
                {
                    value: 1,
                    text: 1
                }
            ],
            perPageValue: 10,

            report: {
                total_paid: 100,
                total_not_paid: 13,
            },

            mapOptions: {
                chart: {
                    type: 'treemap',
                    height: '100%',
                    width: '100%'
                },
                legend: {
                    show: false
                },

            },
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: '100%',
                    width: '100%'
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        // dataLabels: {
                        //     position: 'top',
                        // },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        return "€ "+val.toFixed(2);
                    },
                    style: {
                        colors: ['#fff'] // This will set the color of the percentage text. Change as needed.
                    }
                },
                labels: [
                    'PAID',
                    'NOT_PAID'
                ],
                colors: [
                    '#28c77a', //Paid
                    '#ff9800', //Partial
                    '#00cfe8', //Overpaid

                    '#ea5455', //Not paid
                    '#79082A', //Overdue
                    'rgba(185,185,195,0.6)', //Void
                ],
                // responsive: [{
                //     breakpoint: 480,
                //     options: {
                //         legend: {
                //             position: 'bottom'
                //         }
                //     }
                // }]
            },
            mapEntries: [],


        }
    },

    created(){
        this.fetchPromos();
    },
    computed: {
        series() {
            let series = []

            if (this.report) {

                series = [
                {
                    data: [200, 50, 60]
                },
                {
                    data: [44]
                },
                {
                    data: [0,44]
                }
                ]
            }
            return series
        },
        mapSeries() {
            let mapSeries =  [
                { data: []}
            ];

            if(this.tableRows.length > 0) {
                this.tableRows.forEach((item) => {
                    mapSeries[0].data.push({
                        x: item.attributes.name,
                        y: item.attributes.totalUsed
                    })
                });
            }

            console.log("mapSeries: ", mapSeries)
            return mapSeries;
        },
        hasMapEntries () {
            return this.mapSeries[0].data.length > 0;
        },
        labels() {
            // let labels = []
            //
            // if (this.report) {
            //     labels = [
            //         'PAID',
            //         'NOT_PAID'
            //     ]
            // }
            return [
                'BILLABLE THIS MONTH',
                'INVOICED',
                'PAID',
            ]
        },
        tableRows() {
            let rows = this.$store.state.privateAgencyClients;
            if( this.$store.state.userCompany
                && this.$store.state.userCompany.attributes.isAgency
                && !this.$store.state.userCompany.attributes.core
            ){
                const isUserCompanyPresent = rows.some(item => item.id === this.$store.state.userCompany.id);
                if (!isUserCompanyPresent) {
                    rows.push(this.$store.state.userCompany);
                }
            }

            let suspended = rows.filter((item) => {
                return item.attributes.suspended;
            });

            let active = rows.filter((item) => {
                return !item.attributes.suspended;
            });

            return this.showSuspended ? suspended : active;
        },


        resultsFrom() {
            if(this.$store.state.employeeCardCount == 0) {
                return 0;
            }
            else {
                return ((this.curPageValue-1)*this.perPageValue)+1;
            }

        },
        resultsTo() {
            let pageMaxResults = this.curPageValue*this.perPageValue;

            if(pageMaxResults > this.$store.state.employeeCardCount){
                return this.$store.state.employeeCardCount;
            }else{
                return pageMaxResults;
            }
        },
    },
    methods: {
        downloadQR(promo, format = 'png') {
            let targetUrl = this.composePromo(promo);
            let backendQRUrl = process.env.VUE_APP_API_URL+'/helpers/utils/qrFileGenerator.php?outputType='+format+'&qrURL='+targetUrl;
            window.open(backendQRUrl+targetUrl, '_blank')
        },
        composePromo(promo) {
            if(promo && promo.attributes){
                return window.location.origin + '/signup/?promo=' + promo.attributes.code;
            } else {
                return window.location.origin + '/signup/';
            }
        },
        copyLink(promo){
            let invitationLink = this.composePromo(promo);
            navigator.clipboard.writeText(invitationLink);

            this.$notify({
                group: 'notification',
                type: 'success',
                text: this.$ml.get('PROMO_LINK_COPIED')
            });
        },
        hasExpired(item) {
            return new Date(item.attributes.validUntil) < new Date();
        },
        onSelectAll(checked) {
            this.selectedRows = {};
            if (checked) {
                this.tableRows.forEach(row => {
                    this.selectedRows[row.id] = row.id;
                });
            }
        },

        fetchPromos() {
            this.$store.dispatch('getPromos');
        },
        editPromo(promo) {
            this.currentPromo = [...promo.attributes];
        },
        deletePromo(id) {
            this.$store.dispatch('deletePromo', id);
        },


        /*PAGINATION */
        maxPages() {
            return Math.ceil(this.totalResults/this.resultsPerPage);
        },
        changeCurPage(direction) {

            if(direction == 'next') {
                if(this.curPageValue < this.maxPages){
                    this.curPageValue++;
                }
            }else if (direction == 'prev') {
                if(this.curPageValue > 1){
                    this.curPageValue--;
                }
            }

        },
    }

}
</script>

<style>
.company-logo {
    max-height: 20px;
    max-width: 50px;
}

.qf-stats-container {
    max-width: 800px;
    width: 100%;
    min-width: 500px;
}

.qf-charts {
    width: 100%;
    display: flex;
}

</style>
