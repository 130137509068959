import Parse from 'parse'
//import moment from 'moment'

export const PromoService = {

    getPromos(context) {
        let Promo = Parse.Object.extend('Promo');
        let query = new Parse.Query(Promo);
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                context.commit('setPromos', response);
                resolve(response)
            }).catch(error => {
                self.$checkTokenExp(error);
                reject(error);
            })
        });
    },
    savePromo(context, promo) {
        let Promo = Parse.Object.extend('Promo');
        let newPromo = new Promo();
        return new Promise((resolve, reject) => {
            newPromo.save(promo).then(response => {
                context.commit('addPromo', response);
                resolve(response);
            }).catch(error => {
                self.$checkTokenExp(error);
                reject(error);
            })
        });
    }
};
