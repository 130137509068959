import Parse from 'parse'
import Api from './Api'

export const EmployeeService = {

    async getClientList(context){
        return new Promise((resolve, reject) => {
            let allClients = [];
            context.dispatch('getCompany').then((userCompany) => {
                if(userCompany.attributes.core){
                    context.dispatch('getAdminClients').then(() => {
                        allClients = context.state.agencyClients.map(client => {return client.id});
                        resolve(allClients);
                    });
                }else{
                    context.dispatch('getAgencyClients').then(() => {
                        allClients = context.state.agencyClients.map(client => {return client.id});
                        resolve(allClients);
                    });
                }
            }).catch(err => {
                reject(err);
            });
        });
    },

    async getAvailableUsers(context, filters = null) {
        let self = this;
        let resultsPerPage = context.state.usersPP;
        let cardsPage = context.state.usersPage;
        let allClients = await context.dispatch('getClientList');

        let parseUsers = Parse.User;
        let query = new Parse.Query(parseUsers);

        query.include('company');
        query.descending('createdAt');

        if(!context.state.user.attributes.isAdmin){
            query.notEqualTo('stealth', true);
        }

        if(filters && filters.agencyId){
            // Create a subquery for the Company class
            const Company = Parse.Object.extend('Company');
            const companyQuery = new Parse.Query(Company);
            let agencyPointer = {"__type": "Pointer", "className": "Company", "objectId":  filters.agencyId}
            companyQuery.equalTo('clientOf', agencyPointer);

            // Use the subquery to filter users
            query.matchesQuery('company', companyQuery);
        } else if(!context.state.user.attributes.isAdmin) {
            allClients.push(context.state.user.attributes.company.id);
            query.containedIn('company', allClients);
        }

        if(filters && filters.status) {
            if(filters.status === 'onboarded'){
                query.equalTo('onboarded', true);
            }else if (filters.status === 'pending') {
                query.notEqualTo('onboarded', true);
            }
        }

        if(filters && filters.searchTerm){
            let regEx = '.*'+filters.searchTerm+".*";

            let fnameQuery =new Parse.Query(parseUsers);
            fnameQuery.matches('firstName', regEx, 'i');

            let lnameQuery = new Parse.Query(parseUsers);
            lnameQuery.matches('lastName', regEx, 'i');

            let mailQuery = new Parse.Query(parseUsers);
            mailQuery.matches('username', regEx, 'i');

            let combinedOrQueries = Parse.Query.or(fnameQuery, lnameQuery, mailQuery);
            /*
            query.matches('firstName', regEx, 'i');
            */
            let finalQuery = Parse.Query.and(query, combinedOrQueries)

            return new Promise((resolve, reject) => {
                finalQuery.find().then(response => {
                    context.commit('setVisibleUsers', response);
                    resolve(response)
                }).catch(error => {
                    self.$checkTokenExp(error)
                    reject(error);
                })
            });

        } else {

            query.limit(resultsPerPage);
            query.skip((cardsPage-1)*resultsPerPage);
            return new Promise((resolve, reject) => {
                query.find().then(response => {
                    context.commit('setVisibleUsers', response);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            });
        }

    },
    getTotalUserCount(context) {
        let parseUsers = Parse.User;
        let query = new Parse.Query(parseUsers);
        return new Promise((resolve, reject) => {
            query.count().then(response => {
                context.commit('setTotalUserCount', response);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getClientUsers(context, filters = null) {
        let self = this;
        let cardsPerPage = context.state.usersPP;
        let cardsPage = context.state.usersPage;

        let currentClientId = context.state.cardCompany.id;

        let parseUsers = Parse.User;
        let query = new Parse.Query(parseUsers);

        query.include('company');
        query.descending('createdAt');

        query.containedIn('company', [currentClientId]);

        if(!context.state.user.attributes.isAdmin){
            query.notEqualTo('stealth', true);
        }

        if(filters && filters.searchTerm){
            let regEx = '.*'+filters.searchTerm+".*";

            let fnameQuery =new Parse.Query(parseUsers);
            fnameQuery.matches('firstName', regEx, 'i');

            let lnameQuery = new Parse.Query(parseUsers);
            lnameQuery.matches('lastName', regEx, 'i');

            let mailQuery = new Parse.Query(parseUsers);
            mailQuery.matches('login', regEx, 'i');

            let combinedOrQueries = Parse.Query.or(fnameQuery, lnameQuery, mailQuery);
            /*
            query.matches('firstName', regEx, 'i');
            */
            let finalQuery = Parse.Query.and(query, combinedOrQueries)

            return new Promise((resolve, reject) => {
                finalQuery.find().then(response => {
                    context.commit('setVisibleUsers', response);
                    resolve(response)
                }).catch(error => {
                    self.$checkTokenExp(error)
                    reject(error);
                })
            });

        } else {

            query.limit(cardsPerPage);
            query.skip((cardsPage-1)*cardsPerPage);

            return new Promise((resolve, reject) => {
                query.find().then(response => {
                    context.commit('setVisibleUsers', response);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            });
        }

    },
    getEmployeeCardCount(context) {
        let employees = Parse.Object.extend('Employee');
        let query = new Parse.Query(employees);
        query.equalTo('company', context.state.cardCompany);
        return new Promise((resolve, reject) => {
            query.count().then(response => {
                context.commit('setEmployeeCardCount', response);
                resolve(response);
        }).catch(error => {
                reject(error);
            })
        });
    },
    getEmployeeCards(context, filters = null) {
        let self = this;

        let cardsPerPage = context.state.employeeCardsPP;
        let cardsPage = context.state.employeeCardPage;
        let employees = Parse.Object.extend('Employee');
        let query = new Parse.Query(employees);

        query.equalTo('company', context.state.cardCompany);
        query.include('officeAddress');
        query.include('cardVer');
        query.descending('createdAt');
        query.equalTo('company', context.state.cardCompany);

        if(filters && filters.searchTerm){
            let regEx = '.*'+filters.searchTerm+".*";

            let fnameQuery =new Parse.Query(employees);
            fnameQuery.matches('firstName', regEx, 'i');

            let lnameQuery = new Parse.Query(employees);
            lnameQuery.matches('lastName', regEx, 'i');

            let mailQuery = new Parse.Query(employees);
            mailQuery.matches('email', regEx, 'i');

            let combinedOrQueries = Parse.Query.or(fnameQuery, lnameQuery, mailQuery);
            /*
            query.matches('firstName', regEx, 'i');
            */
            let finalQuery = Parse.Query.and(query, combinedOrQueries)

            return new Promise((resolve, reject) => {
                finalQuery.find().then(response => {
                    context.commit('setEmployeeCards', response);
                    resolve(response)
                }).catch(error => {
                    self.$checkTokenExp(error)
                    reject(error);
                })
            });

            //query.contains('lastName', filters.searchTerm);
            //query.contains('login', filters.searchTerm);
        } else {
            query.limit(cardsPerPage);
            query.skip((cardsPage-1)*cardsPerPage);

            return new Promise((resolve, reject) => {
                query.find().then(response => {
                    context.commit('setEmployeeCards', response);
                    resolve(response)
                }).catch(error => {
                    self.$checkTokenExp(error)
                    reject(error);
                })
            });
        }


    },
    getAllEmployeeCards(context){
        let employees = Parse.Object.extend('Employee');
        let query = new Parse.Query(employees);
        query.equalTo('company', context.state.cardCompany);
        query.limit(1000);
        query.descending('createdAt');
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                //context.commit('setEmployeeCards', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getCompAddresses(context) {
        let compAddresses = Parse.Object.extend('OfficeAddress');
        let query2 = new Parse.Query(compAddresses);
        query2.equalTo('company', context.state.cardCompany);
        //query2.includeAll();
        return new Promise((resolve, reject) => {
            query2.find().then(response => {
                context.commit('setOfficeAddresses', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },

    getOnboardAddresses(context, data) {
        let formData = new FormData();

        formData.append('action', 'getOfficeAddresses');
        formData.append('data', JSON.stringify(data));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });

    },
    sendSingleInvite(context, data) {
        let formData = new FormData();

        formData.append('action', 'sendSingleCardLink');
        formData.append('data', JSON.stringify(data));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },

    setCardPage(context, cardPage) {
        context.commit('setEmployeeCardPage', cardPage);
    },
    setCardsPP(context, cardsPP) {
        context.commit('setEmployeeCardsPP', cardsPP);
    },

    selectCard(context, selectedAddressId){
        let selected = context.state.officeAddresses.find(obj => {
            if(obj.id === selectedAddressId){
                return obj;
            }else{
                return false;
            }
        });
        return selected;
    },

    saveEmployeeCards(context, data) {
        
        let addrObject = { "__type": "Pointer", "className": "OfficeAddress", "objectId": data.officeAddress.id }

        if (data.id === null) {
            let employeeCard = Parse.Object.extend('Employee');
            let employeeCardRecord = new employeeCard();
            return new Promise((resolve, reject) => {
                if(data.imgFiles && data.imgFiles.profilePicture){
                    let file = new Parse.File(data.imgFiles.profilePicture.name, data.imgFiles.profilePicture);
                    employeeCardRecord.set('profilePicture', file);
                }
                employeeCardRecord.set('firstName', data.firstName);
                employeeCardRecord.set('lastName', data.lastName);
                employeeCardRecord.set('position', data.position);
                employeeCardRecord.set('department', data.department);
                employeeCardRecord.set('mobilePhone', data.mobilePhone);
                employeeCardRecord.set('officePhone', data.officePhone);
                employeeCardRecord.set('socialLinkedIn', data.socialLinkedIn);
                employeeCardRecord.set('socialPersonalTwitter', data.socialPersonalTwitter);

                employeeCardRecord.set('email', data.email);
                employeeCardRecord.set('notes', data.notes);
                employeeCardRecord.set('cardToken', data.randString);
                employeeCardRecord.set('officeAddress', addrObject);
                employeeCardRecord.set('company', context.state.cardCompany);
                employeeCardRecord.set('cardVer', 1);
                employeeCardRecord.save().then(response => {
                    context.dispatch('getEmployeeCards');

                    context.state.cardCompany.increment('currentCardCount');
                    context.state.cardCompany.save();

                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        }
        else {
            return new Promise((resolve, reject) => {
                if(data.imgFiles && data.imgFiles.profilePicture){
                    let file = new Parse.File(data.imgFiles.profilePicture.name, data.imgFiles.profilePicture);
                    context.getters.selectedModalData('employeeCards').set('profilePicture', file);
                }

                context.getters.selectedModalData('employeeCards').set('firstName', data.firstName);
                context.getters.selectedModalData('employeeCards').set('lastName', data.lastName);
                context.getters.selectedModalData('employeeCards').set('position', data.position);
                context.getters.selectedModalData('employeeCards').set('department', data.department);
                context.getters.selectedModalData('employeeCards').set('mobilePhone', data.mobilePhone);
                context.getters.selectedModalData('employeeCards').set('officePhone', data.officePhone);
                context.getters.selectedModalData('employeeCards').set('socialLinkedIn', data.socialLinkedIn);
                context.getters.selectedModalData('employeeCards').set('socialPersonalTwitter', data.socialPersonalTwitter);

                context.getters.selectedModalData('employeeCards').set('email', data.email);
                context.getters.selectedModalData('employeeCards').set('notes', data.notes);
                //context.getters.selectedModalData('employeeCards').set('address', data.address);
                context.getters.selectedModalData('employeeCards').set('officeAddress', addrObject);
                context.getters.selectedModalData('employeeCards').set('company', context.state.cardCompany);
                context.getters.selectedModalData('employeeCards').set('cardVer', data.cardVer ? Number(data.cardVer)+1 : 1);

                context.getters.selectedModalData('employeeCards').save().then(response => {
                    context.dispatch('getEmployeeCards');

                    context.state.cardCompany.increment('currentCardCount');
                    context.state.cardCompany.save();

                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        }
    },
    deleteEmployeeCards(context) {
        return new Promise((resolve, reject) => {
            let allCards = context.getters.selectedModalData('employeeCards');
            Parse.Object.destroyAll(allCards).then(response => {
                context.dispatch('getEmployeeCards');
                context.state.cardCompany.increment('currentCardCount', -allCards.length);
                context.state.cardCompany.save();
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    },
    shareCard(context, data) {
        let formData = new FormData();
        formData.append('action', 'sendCardLink');
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify({
            sender_id: context.state.user.id,
            company_id: context.state.cardCompany.id,
            employee_ids: [data.id],  // Notice this has changed to a single ID
            reset_link: data.resetLink
        }));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    shareCards(context, data) {
        let formData = new FormData();
        formData.append('action', 'sendCardLink');
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify({
            sender_id: context.state.user.id,
            company_id: context.state.cardCompany.id,
            employee_ids: data.ids,
            reset_link: data.resetLink
        }));
        
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    importEmployees(context, formData) {
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    selfOnboardEmployee(context, data){
        let formData = new FormData();

        formData.append('action', 'createCard');
        formData.append('data', JSON.stringify(data));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });

    }
};
