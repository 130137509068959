<template>
    <div>
        <transition name="fade">
            <div class="trial-stripe" v-if="trialOpen" :class="addClass">
                <div v-if="curCompStatus === '01_new'" class="notice-middle py-1">
                    <div class="d-flex flex-column">
                        <span class="notice-text ">Your digital business card design is currently being prepared! Card preview and sharing is therefore not yet available! </span>
                        <span class="notice-text ">You will receive a notification e-mail once everything is ready.</span>
                    </div>
                </div>

                <div v-else-if="curCompStatus === '02_ready'" class="notice-middle py-1">
                    <div class="d-inline-block">
                        <div class="d-flex flex-column">
                            <span class="notice-text">Your card design is now ready! Click on the Activate button to start your trial.</span>
                        </div>
                    </div>

                    <button-field
                        class="ml-2"
                        @click="openActivate"
                        color="dark" variant="primary"
                        :icon="`check`">Activate Trial</button-field>

                </div>

                <div v-else class="notice-middle">
                    <span class="notice-text mr-2" v-if="hasEverSubscribed">Your subscription is active only until {{nextBillingDate}}! </span>
                    <span class="notice-text mr-2" v-else-if="trialDaysRemaining > 0">Your account is in trial period. You currently have <strong>{{trialDaysRemaining}} day(s)</strong> remaining. </span>
                    <span class="notice-text mr-2" v-else-if="trialDaysRemaining == 0">Your trial is expiring <strong>today!</strong> Click the button on the right to avoid account suspension! </span>
                    <span class="notice-text mr-2" v-else-if="trialDaysRemaining < 0">Your account trial has expired <strong>{{-1*trialDaysRemaining}} day(s) ago!</strong></span>
                    <button-field v-if="hasEverSubscribed"
                                  @click="openSubscribe"
                                  color="dark" variant="primary"
                                  :icon="`check`">Reactivate Subscription</button-field>
                    <button-field v-else
                                  @click="openSubscribe"
                                  color="dark" variant="primary"
                                  :icon="`check`">Activate Subscription</button-field>
                </div>
                <div class="notice-close_control"><font-awesome-icon icon="times" @click="closeTrialNotice"></font-awesome-icon></div>
            </div>



        </transition>

        <b-modal
            id="activate-trial"
            title="Qfecard 14-day Trial Activation"
            :ok-title="'Activate now'"
            :ok-variant="'secondary'"
            :cancel-variant="'outline-dark'"
            @ok="activateTrial"
        >
            <div class="text-center">
                <b-img :src="trialIcon" fluid alt="Trial period" width="100" class="mb-3"></b-img>
                <p class="font-small-2">You are now activating your 14-day trial valid until:</p>
                <strong class="text-danger">{{ trialEndDate }}</strong>
                <p class="pt-3 font-small-2">You will be able to use all features of Qfecard for free during this period without any limitations to the number of created or installed cards.</p>
                <p class="font-small-2">Make sure to send out and onboard (install) the cards for your colleagues to get the most out of the trial period!</p>

            </div>
        </b-modal>
    </div>

</template>

<script>

import Parse from "parse";
import moment from "moment/moment";

export default {
    data() {
        return {
            trialNoticeOpen: true,
            trialIcon: require('@/assets/images/icons/trial-icon.svg'),
        }
    },
    computed: {
        trialEndDate() {
            return moment().add(14, 'days').format('Do [of] MMMM YYYY');
        },
        addClass(){
            if(this.curCompStatus === '01_new' || this.curCompStatus === '02_ready'){
                return 'design-in-progress';
            }
            if(this.trialDaysRemaining < 0){
                return 'trial-expired';
            }else if(this.trialDaysRemaining == 0){
                return 'trial-expiring';
            }else{
                return 'trial-active';
            }
        },
        curCompStatus(){
            let compStatus = this.userCompany ? this.userCompany.attributes.status : null;
            console.log(compStatus);
            return compStatus;
        },

        designReady() {
            if(this.userCompany && this.userCompany.attributes.status !== '01_new' && this.userCompany.attributes.status !== '02_ready'){
                return true;
            }else{
                return false;
            }
        },
        hasEverSubscribed(){
            let hasSubscribed = this.userCompany ? this.userCompany.attributes.subscribedOn : false;
            return hasSubscribed;
        },
        nextBillingDate() {
            const today = moment();
            const thirdOfThisMonth = moment().startOf('month').add(2, 'days'); // 3rd of current month

            if (today.isBefore(thirdOfThisMonth)) {
                // If today's date is before the 3rd of the current month
                return thirdOfThisMonth.format('D/M/YYYY');
            } else {
                // If today's date is on or after the 3rd of the current month
                const thirdOfNextMonth = thirdOfThisMonth.add(1, 'month');
                return thirdOfNextMonth.format('D/M/YYYY');
            }
        },
        curUser(){
            return Parse.User.current();
        },
        userCompany(){
            return this.$store.state.userCompany;
        },
        curRoute(){
          return this.$route.name;
        },

        isCore(){
            return this.$store.state.userCompany.attributes.isCore;
        },

        trialOpen() {
            if(this.curCompStatus === '01_new' || this.curCompStatus === '02_ready'){
                return true;
            }else if(this.curCompStatus === '04_active') {
                return false;
            }else {
                let userTrial = this.curUser ? this.curUser.attributes.trialUntil : false;
                let companyTrial = this.userCompany ? this.userCompany.attributes.trialUntil : false;

                if(this.trialNoticeOpen &&
                    (userTrial || companyTrial)
                    && this.curRoute !== 'suspended'
                    && this.curRoute !== 'profile'
                    && this.curRoute !== 'terms-regular'
                    && this.curRoute !== 'terms-agency'
                    && this.curRoute !== 'activate-subscription'
                ) {
                    return true;
                } else  {
                    return false;
                }
            }

        },

        trialDaysRemaining() {
            let userTrial = this.curUser && this.curUser.attributes.trialUntil ? moment(this.curUser.attributes.trialUntil) : null;
            let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;

            let maxTrial = null;

            // Check if both dates are valid
            if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                maxTrial = moment.max(userTrial, companyTrial);
            } else if (userTrial && userTrial.isValid()) {
                // Only user trial date is valid
                maxTrial = userTrial;
            } else if (companyTrial && companyTrial.isValid()) {
                // Only company trial date is valid
                maxTrial = companyTrial;
            }

            if (maxTrial) {
                let remainingTrial = maxTrial.diff(moment(), 'days');
                return remainingTrial;
            } else {
                return 0;
            }
        },

        hasTrial() {
            let hasTrial = this.curUser ? this.curUser.attributes.trialUntil : false;

            if(hasTrial) {
                return true;
            } else  {
                return false;
            }
        },
    },
    methods: {
        activateTrial(){
            this.$store.state.userCompany.set('trialUntil', moment().add(15, 'days').toDate());
            this.$store.state.userCompany.set('status', '03_trial');
            this.$store.state.userCompany.save().then(() => {
                console.log('Trial activated');
                this.$bvModal.hide('activate-trial');
                this.$store.dispatch('refreshUserCompany');
                this.$notify({
                    group: 'notification',
                    title: 'Trial activated',
                    text: 'Trial activated successfully!',
                    type: 'success'
                });
            }).catch((error) => {
                this.$notify({
                    group: 'notification',
                    title: 'Error',
                    text: 'An error occurred while activating your trial. Please try again later.',
                    type: 'error'
                });
                console.error('Error while saving trial activation', error);
            });
        },
        closeTrialNotice(){
            this.trialNoticeOpen = false;
        },

        openActivate(){
            this.$bvModal.show('activate-trial');
        },

        openSubscribe(){
               this.$router.push({name: 'activate-subscription'});
        },
    },

}
</script>

<style>

.design-in-progress {
    background-color: #f8d7da;
    color: #721c24;
}

</style>
